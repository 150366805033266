import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const QuestionFR10 = () => {

    return (
        <>
       test en
        </>
    );
}

export default QuestionFR10;